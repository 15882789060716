import { Controller } from '@hotwired/stimulus';
import fetchTurboFrame from '../utils';

export default class extends Controller {
  static targets = ['form'];

  updateDiscipline() {
    const adminNamespace = this.formTarget.action.includes('/admin/') ? '/admin' : '';
    fetchTurboFrame(`${adminNamespace}/quotes/new?${this.quoteParams()}`);
  }

  quoteParams() {
    const params = new URLSearchParams();

    const customer = this.formTarget.querySelector('select[name="quote[customer_id]"]');
    if (customer) {
      params.append('quote[customer_id]', customer.value);
    }
    params.append('quote[discipline]', this.formTarget.querySelector('input[name="quote[discipline]"]:checked').value);
    this.formTarget.querySelectorAll('input[name="quote[artworks_attributes][][garment_count]').forEach((input) => {
      params.append('quote[artworks_attributes][][garment_count]', input.value);
    });
    this.formTarget.querySelectorAll('input[name="quote[option_price_ids][]"]').forEach((input) => {
      params.append('quote[option_price_ids][]', input.value);
    });

    return params;
  }
}
