import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';
import fetchTurboFrame from '../utils';

export default class extends Controller {
  static targets = ['accountSelect', 'ccdSalespersonSelect', 'firmChargeDelete', 'firmShipDate', 'firmShipOption', 'salespersonSelect', 'shipHideable'];

  static values = {
    optionPricesUrl: String,
    url: String,
  };

  deliveryMethodChange(e) {
    const deliveryMethod = e.currentTarget.value;
    this.shipHideableTargets.forEach((el) => {
      el.hidden = el.id !== deliveryMethod;
    });
  }

  firmShipDateChange() {
    fetchTurboFrame(`${this.optionPricesUrlValue}?firm_ship_date=${this.firmShipDateTarget.checked}`);
  }

  radioChange() {
    // ensure _destroy checkbox is un-checked once a radio button is selected
    this.firmChargeDeleteTarget.checked = false;
  }

  selectChange() {
    fetchTurboFrame(`${this.urlValue}?${this.params()}`);
  }

  updateSubmit(e) {
    e.preventDefault();
    // change form `turbo` attribute to enable turbo form submission
    this.element.setAttribute('data-turbo', true);
    this.element.dataset.turbo = true;
    Turbo.navigator.submitForm(this.element);
  }

  params() {
    const params = new URLSearchParams();
    params.append('account_id', this.accountSelectTarget.value);
    params.append('ccd_sales_rep_select', this.ccdSalespersonSelectTarget.id);
    params.append('sales_rep_select', this.salespersonSelectTarget.id);
    return params;
  }
}
