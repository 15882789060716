import { Controller } from '@hotwired/stimulus';
import fetchTurboFrame from '../utils';

// Connects to data-controller="international-address"
export default class extends Controller {
  static targets = ['stateInput'];

  static values = { url: String };

  selectCountry(event) {
    const url = new URL(this.urlValue);
    const params = new URLSearchParams(url.search);
    params.append('state_select', this.stateInputTarget.id);
    params.append('country_code', event.currentTarget.selectedOptions[0].value);
    url.search = params.toString();
    fetchTurboFrame(url.toString());
  }
}
